<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="8">
          <v-card class="rounded-lg shadow" :loading="isLoading">
            <v-card-text class="pa-0">

              <h2 class="text-center pt-5">ECSEL EXPO</h2>

              <v-divider class="my-5"/>

              <div class="pa-8">
                <v-row>
                  <v-col>
                    <p><strong>Total Participants :</strong> {{ statistics.total_participant }}</p>
                  </v-col>
                  <v-col>
                    <p><strong>Total Gifts :</strong> {{ statistics.total_win }}</p>
                  </v-col>
                  <v-col>
                    <p><strong>Montant Total des Gifts :</strong> {{ statistics.total_win_amount }}</p>
                  </v-col>
                </v-row>

                <h3 class="mt-4">Gifts Par Date</h3>
                <v-list>
                  <v-list-item v-for="(win, index) in statistics.total_win_per_date" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>Date : {{ win.date }}</v-list-item-title>
                      <v-list-item-subtitle>
                        Total des Gifts : {{ win.total_win }} - Montant Total : {{ win.total_amount }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>


                <h3 class="mt-4">Gifts Par Montant</h3>
                <v-list>
                  <v-list-item v-for="(amount, index) in statistics.total_win_per_amount" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>Montant : {{ amount.amount }}</v-list-item-title>
                      <v-list-item-subtitle>
                        Total des Gifts : {{ amount.total_win }} - Montant Total : {{ amount.total_amount }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>


                <h3 class="mt-4">Participants Par Date</h3>
                <v-list>
                  <v-list-item v-for="(participant, index) in statistics.total_participant_per_date" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>Date : {{ participant.date }}</v-list-item-title>
                      <v-list-item-subtitle>
                        Total des Participants : {{ participant.total_participant }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      statistics: {},
      isLoading: false
    };
  },
  mounted() {
    this.fetchStatistics();
  },
  methods: {
    async fetchStatistics() {
      this.isLoading = true;
      try {
        const apiUrl = `${process.env.VUE_APP_BASE_URL}gifty-games/global-statistics`;
        const response = await axios.get(apiUrl);

        if (response.data.success) {
          this.statistics = response.data;
        } else {
          console.error('Échec de la récupération des données des statistiques');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques :', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
</style>
